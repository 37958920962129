.home {
	margin-top: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.home .container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--normal-gap);
}

.home__logo {
	width: 50%;
}

.home__desc {
	display: flex;
	flex-direction: column;
	gap: var(--small-gap);

	font-size: var(--md);
	letter-spacing: var(--text-spacing);
	text-align: justify;
}

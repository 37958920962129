.header {
	width: 100%;
	height: var(--header-footer-height);
	background-color: var(--dark-main);

	position: fixed;
	top: 0;
	left: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
}

.header__nav {
	width: var(--container-width);

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header__logo {
	width: var(--logo-width);
}

.header__logo:hover {
	cursor: pointer;
	filter: grayscale(100%) brightness(1000%);
}

.header__nav__list {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: var(--normal-gap);
}

.nav__list__item {
	color: var(--light-accent);
	font-size: var(--md);

	font-family: var(--secondary-font);
	text-transform: uppercase;
	text-shadow: var(--text-shadow);
}

.nav__list__item:hover {
	cursor: pointer;
	color: var(--text-color);
}

.character {
	position: relative;
	width: calc(400px - var(--normal-gap));
	height: 300px;

	border: var(--card-border);
	border-radius: var(--card-radius);
	box-shadow: var(--card-shadow);

	overflow: hidden;
	transition: all 0.25s ease-in-out;
}

.character__image {
	width: 100%;
	height: 100%;

	object-fit: cover;
	aspect-ratio: 16 / 9;
}

.character__overlay {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background-color: rgba(0, 0, 0, 0.5);

	display: flex;
	justify-content: center;
	align-items: center;

	opacity: 0;
	transition: all 0.25s ease-in-out;
}

.character__name {
	color: var(--text-color);
	font-family: var(--secondary-font);
	font-size: var(--xl);
	text-transform: uppercase;
	text-shadow: var(--text-shadow);

	opacity: 0;
	transform: translateY(-100px);
	transition: all 0.15s ease-in-out;
}

.character:hover {
	cursor: pointer;
	transform: scale(0.975) !important;
}

.character:hover .character__overlay {
	opacity: 1;
}

.character:hover .character__name {
	opacity: 1;
	transform: translateY(0);
}

@media (max-width: 1199px) {
	.character__overlay {
		opacity: 1;
	}

	.character__name {
		opacity: 1;
		transform: translateY(0);
	}
}

/**TABLET*/
@media (min-width: 768px) and (max-width: 1199px) {
	.character {
		position: relative;
		width: calc(33% - var(--normal-gap));
		height: 200px;
	}
}

/**MOBILE*/
@media (max-width: 767px) {
	.character {
		position: relative;
		width: calc(50% - var(--normal-gap));
		height: 150px;
	}
}

.details {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.details .container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	gap: var(--normal-gap);
}

.details__intro {
	width: 100%;
	height: 600px;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.details__cutout {
	width: 60%;
	height: 100%;

	object-fit: contain;
	aspect-ratio: 16 / 9;
}

.details__intro__desc {
	width: calc(100% - var(--normal-gap));
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-start;
}

.details__name {
	color: var(--text-color);
	font-family: var(--secondary-font);
	font-size: var(--xl);
	text-transform: uppercase;
	text-shadow: var(--text-shadow);
}

.details__intro__desc p {
	font-size: var(--sm);
	letter-spacing: var(--text-spacing);
	text-align: justify;
}

.details__intro__desc p::first-letter {
	margin-right: 3px;

	color: var(--light-accent);
	font-size: var(--md);
	font-family: var(--secondary-font);
	font-weight: bold;
}

/**TABLET*/
@media (min-width: 768px) and (max-width: 1199px) {
	.details__intro {
		height: fit-content;
		gap: var(--normal-gap);
	}

	.details__cutout {
		width: 300px;
		height: 500px;
		object-fit: contain;
	}

	.details__intro__desc {
		width: calc(100% - var(--normal-gap));
		height: fit-content;

		gap: var(--normal-gap);
	}
}

/**MOBILE*/
@media (max-width: 767px) {
	.details__intro {
		height: fit-content;

		flex-direction: column;
		gap: var(--normal-gap);
	}

	.details__cutout {
		display: none;
	}

	.details__intro__desc {
		width: 100%;
		height: fit-content;

		gap: var(--normal-gap);
	}

	.details__name {
		align-self: center;
	}
}

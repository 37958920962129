@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cantora+One&display=swap");
:root {
	/**COLORS*/
	--dark-main: rgb(23, 23, 23);
	--dark-accent: rgb(43, 43, 43);
	--light-main: rgb(237, 237, 237);
	--light-accent: rgb(187, 20, 20);
	--text-color: var(--light-main);

	/**TEXT*/
	--main-font: "Red Hat Display", sans-serif;
	--secondary-font: "Cantora One", sans-serif;
	--base: 18px;
	--xl: 3rem;
	--lg: 2rem;
	--md: 1.5rem;
	--sm: 1.15rem;
	--text-spacing: 1px;

	/**WIDTHS*/
	--container-width: 1200px;
	--logo-width: 50px;

	/**HEIGHTS*/
	--header-footer-height: 100px;

	/**PADDINGS*/
	--simple-padding: 1em;
	--small-padding: 0.25em;

	/**GAPS*/
	--normal-gap: 1em;
	--small-gap: 0.5em;

	/**UTILITY*/
	--card-border: solid 3px var(--dark-accent);
	--card-radius: 10px;
	--card-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.75);
	--text-shadow: 3px 4px 0px var(--dark-accent);
}

/**TABLET*/
@media (min-width: 768px) and (max-width: 1199px) {
	:root {
		/**TEXT*/
		--xl: 2rem;
		--lg: 1.5rem;
		--md: 1.25rem;
		--sm: 1rem;

		/**WIDTHS*/
		--container-width: 95%;
		--logo-width: 40px;

		/**HEIGHTS*/
		--header-footer-height: 80px;
	}
}

/**MOBILE*/
@media (max-width: 767px) {
	:root {
		/**TEXT*/
		--xl: 1.75rem;
		--lg: 1.25rem;
		--md: 1rem;
		--sm: 0.85rem;

		/**WIDTHS*/
		--container-width: 90%;
		--logo-width: 30px;

		/**HEIGHTS*/
		--header-footer-height: 60px;

		/**GAPS*/
		--normal-gap: 0.75em;
		--small-gap: 0.35em;
	}
}

html {
	color: var(--text-color);
	font-size: var(--base);
	font-family: var(--main-font);
}

body {
	background-color: var(--dark-main);
}

.page {
	height: fit-content;
	min-height: 100vh;

	background-color: var(--dark-main);
	padding-top: calc(var(--header-footer-height) + var(--small-gap));
}

.container {
	height: 100%;
	width: var(--container-width);
}
